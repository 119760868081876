import * as React from "react";

type Props = {};

const FortniteLandingText: React.FC<Props> = (props) => {
	return (
		<div className="text-start mt-24 pb-8 lg:mb-10 px-[25px] lg:px-[380px] btn-inlay-primary">
			<div className="text-[16px] font-bold underline mb-5">
				<h1>
					Rulebook / Terms and Conditions - Sky City V Bucks Giveaway 2025
				</h1>
			</div>
			<div>
				<p>
					This document outlines the rules to be followed when participating in
					the Sky City V Bucks Giveaway activation. By taking part in the
					activation, you are agreeing to the following terms and conditions.
				</p>
			</div>
			<div className="text-[16px] font-bold my-5">
				<h2>Eligibility</h2>
			</div>
			<div className="text-[13px] font-bold mt-3">
				<ul>
					<li>1. This Promotion is open to eligible individuals who:</li>
					<ul className="ml-4">
						<li>
							I. Aged over 18 years old at the start of the tournament (proof of
							age may be required).
						</li>
						<li>
							II. Resident of the United Kingdom, Channel Islands and Isle of
							Man. Residency’ defined: A player is considered a ‘Resident’ when
							on the date that the player registers for the tournament they are
							either a lawful permanent resident of the UK for at least six
							months.
						</li>
					</ul>
				</ul>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>Entry Period</h2>
			</div>
			<div className="text-[13px]">
				<p>
					2. The promotion opens on 20th March 2025 and closes 3rd April 2025
					(the “Promotional Period”).
				</p>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>Entry Requirements</h2>
			</div>
			<div className="text-[13px]">
				<p>
					3. To enter this Promotion, all you need to do is participate in the
					Sky City V Bucks Giveaway whilst logged into your account until the
					end of the Promotional period and complete the Sky City V Bucks
					Giveaway validation form{" "}
					<a
						className="underline ring-color"
						href="https://encore.fans/fortnite/etw/earn"
					>
						https://encore.fans/fortnite/etw/earn.
					</a>{" "}
					Validation via the form will be required and a player may be
					disqualified if they are unable to validate their entry.
				</p>
				<p className="mt-5">
					4. Entrants using multiple accounts will be disqualified. Entries that
					contain any sexual, offensive, inappropriate, or defamatory content
					will be disqualified. Late, incomplete, or otherwise ineligible
					entries will also be disqualified. The Promoter reserves the right to
					not award prizes if qualifying criteria is not met and the right to
					select replacement entry into the competition element of the
					Promotion.
				</p>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>Format</h2>
			</div>
			<div className="text-[13px]">
				<p>
					5. During the course of the Promotional period, each player competes
					on the Sky City map to get as many eliminations as possible to gain
					entries into the promotion.
					<ul className="list-disc pl-6 space-y-2">
						<li>Every 10 eliminations equates to 1 (one) entry.</li>
						<li>There are no limits to the amount of entries per player.</li>
						<li>
							At the end of the promotional period the 100 players with the most
							entries will be announced as winners providing they are eligible
							based on the Eligibility criteria above (section 1){" "}
							<a
								className="underline ring-color"
								href="https://discord.gg/UmiGamesStudios"
							>
								https://discord.gg/UmiGamesStudios
							</a>{" "}
						</li>
					</ul>
				</p>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>Winner selection and notification</h2>
			</div>
			<div className="text-[13px]">
				<p>
					6. 100 winners will be determined based on the following criteria:
					<ul className="list-disc pl-6 space-y-2">
						<li>
							Total number of entries submitted across the promotional period
						</li>
						<li>
							Checks to confirm the winner is eligible according to the
							Eligibility section above (section 1),
						</li>
						<li>100 winners will be emailed their prize</li>
					</ul>
				</p>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>Prizes</h2>
			</div>
			<div className="text-[13px]">
				<p>
					7. 100 unique winners will receive the following:
					<ul className="list-disc pl-6 space-y-2">
						<li>500 V Bucks (1 per winner)</li>
					</ul>
				</p>
				<p className="mt-5">
					8. There is no cash alternative to the prizes and the prizes are
					non-refundable and non-transferable
				</p>
				<p className="mt-5">
					9. The Promoter reserves the right to substitute the prizes for prizes
					of equivalent if this is necessary for reasons beyond its control.
				</p>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>Data Protection</h2>
			</div>
			<div className="text-[13px]">
				<p>
					10. Any entrant data collected will be used for the purpose of
					administering the promotion and contacting the winners to notify them
					of the prize and, if you are the winner, we may pass them on to third
					parties to deliver the prize.
				</p>
				<p className="mt-5">
					11. If you are a winner of the Promotion, you agree that the Promoter
					may use your first name, surname, county, age, image, gamer tag and
					winning entry for post-event publicity purposes without additional
					remuneration. The promotion will be referenced and endorsed in our
					paid social and influencer posts. You can object to this publication
					or ask for less information to be published or made available by
					contacting us at through the discord channel{" "}
					<a
						className="underline ring-color"
						href="https://discord.gg/UmiGamesStudios"
					>
						https://discord.gg/UmiGamesStudios.
					</a>{" "}
					If you object, this will not affect your entry into the competition,
					but we may still be required to provide information to the Advertising
					Standards Authority. You can view our Privacy Notice on Sky.com.
				</p>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>General</h2>
			</div>
			<div className="text-[13px]">
				<p>
					12. This Promotion is in no way sponsored, endorsed, or administered
					by, or associated with TikTok, YouTube, Meta, X (Twitter), Twitch, and
					they are in no way responsible for any aspect of this promotion.
				</p>
				<p className="mt-5">
					13. The Promoter does not accept any responsibility for network,
					computer, hardware, or software failures of any kind, which may
					restrict or delay the sending or receipt of your entry. Proof of
					sending is not proof of receipt.
				</p>
				<p className="mt-5">
					14. Except in the case of death or personal injury arising from its
					negligence or in respect of fraud and so far as is permitted by law,
					the Promoter and its associated companies and agents exclude
					responsibility and all liabilities arising from the enjoyment of the
					prize; and/or any postponement, cancellation, delay or changes to the
					prize beyond the Promoter’s control; and for any act or default of any
					third party supplier.
				</p>
				<p className="mt-5">
					15. The terms and conditions of any other third-party supplier
					associated with the Promotion will also apply to the prize where
					applicable (for example, the provider of an event or experience). If
					there is a conflict between third party terms and conditions and these
					terms and conditions, these shall take precedence.
				</p>
				<p className="mt-5">
					16. The Promoter will not be liable for any prize(s) that do not reach
					the winners for reasons beyond the Promoter’s reasonable control.
				</p>
				<p className="mt-5">
					17. The Promoter’s decision is final and binding in all respects on
					all entrants. No correspondence will be entered into. Entries that do
					not comply in full with these entry terms and conditions will be
					disqualified.
				</p>
				<p className="mt-5">
					18. The Promoter may refuse or disqualify any entry (including winning
					entries) if there are reasonable grounds to believe there has been a
					breach of these terms and conditions or if the entrant concerned or
					anyone authorised by the entrant to deal with their entry, acts in a
					way towards the Promoter, or other entrants which the Promoter
					reasonably considers to be inappropriate, unlawful or offensive. If
					the winning entry is disqualified the Promoter reserves the right to
					award the prize to another entrant.
				</p>
				<p className="mt-5">
					19. Entrants will be deemed to have accepted these terms and
					conditions and agreed to be bound by them when entering this
					Promotion.
				</p>
				<p className="mt-5">
					20. This Promotion, and any dispute or claim arising out of or in
					connection with it, shall be governed by and construed in accordance
					with English law. You irrevocably agree that the courts of England and
					Wales shall have exclusive jurisdiction to settle any dispute or claim
					that arises out of or in connection with this promotion.
				</p>
			</div>
			<div className="text-[16px] font-bold mt-5">
				<h2>Code of Conduct</h2>
			</div>
			<div>
				<ol className="list-decimal pl-6 space-y-2">
					<li>
						Partaking in any form of stigmatism or harassment is strictly
						forbidden. This includes but is not limited to racism, sexism,
						ableism, hate speech etc.
					</li>
					<li>
						You may not use any type or username in-game or on discord that is
						inappropriate in any way. This includes but is not limited to;
						containing profanity, sexual references, hate speech, hateful
						slogans, condoning terrorism, fascism, child abuse; or that are in
						any way religious, political or prone to creating controversy or
						division.
					</li>
					<li>
						Any threats of Violence or blackmailing to members or staff of the
						competition or discord will be taken seriously and punished.
					</li>
					<li>
						Bullying of other parties through repeated trolling, flaming or
						spamming in a manner that would be considered harassment by the
						receiving party is strictly forbidden.
					</li>
					<li>
						You may not use any alternative accounts to avoid punishment, harass
						others etc.
					</li>
					<li>
						Impersonating members of staff by creating accounts with close
						similarity to another existing Account or user, with perceived
						intentions to impersonate such other user without his/her consent or
						to make comments or create an account causing other users to believe
						they come from Guild Esports Staff or from someone with similar
						authority, will be punished. Which may include permanent bans from
						affiliated competition.
					</li>
					<li>
						No doxing (revealing or otherwise reproducing or supplying to anyone
						another user’s personal information).
					</li>
					<li>
						No phishing (attempting to find out users’ personal information,
						information concerning an Account or other information of a private
						nature from anyone).
					</li>
					<li>
						Cheating, Hacking, Scripting, Botting, use of trainers or other
						hardware or software applications that are designed to impede the
						integrity of any event are strictly prohibited.
					</li>
					<li>
						Ghosting, stream-sniping or any other form of broadcasting foul play
						is forbidden.
					</li>
				</ol>
			</div>
            <div className="mt-5">
				<span className="text-[16px] font-bold">
                    Promoter: {" "}
                </span>
                <span>Sky UK Limited, Grant Way, Isleworth, Middlesex TW7 5QD</span>
			</div>
		</div>
	);
};

export default FortniteLandingText;
