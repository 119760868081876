import ProgramBox from "@components/ProgramBox"

export const Progress = ({filter}) => {
    return (
        <>
            {filter?.isRequiredCompleted ? 
            <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M81 40.4999C81 62.8674 62.8675 80.9999 40.5 80.9999C18.1326 80.9999 0 62.8674 0 40.4999C0 18.1324 18.1326 6.10352e-05 40.5 6.10352e-05C62.8675 6.10352e-05 81 18.1324 81 40.4999Z" fill="#38BC45"/>
                <path d="M22.6218 40.5687L34.4948 52.4418L58.3784 28.5582" stroke="white" strokeWidth="6.26633" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> :
            <div className='shadow-[0px_6px_41px_0px_#0000001F] overflow-hidden fixed z-50 bottom-5 right-3 bg-tile h-[81px] w-[81px] flex justify-center items-center rounded-full'>
                    {
                        filter?.isRequiredCompleted ? <div className='bg-tile h-full w-full flex justify-center items-center'>
                        <p className='font-semibold text-[16px] tile-inlay-primary text-center leading-4'><ProgramBox box="PROGRESSBAR_COMPLETION_TEXT" /></p>
                    </div> : 
                        <>      
                            <svg className="w-full h-full">
                                <circle
                                    className="ring-color opacity-20"
                                    strokeWidth="6"
                                    stroke="currentColor"
                                    fill="transparent"
                                    r="28"
                                    cx="40.5"
                                    cy="40.5"
                                />
                                <circle
                                    className="ring-color"
                                    strokeWidth="6"
                                    strokeDasharray={28 * 2 * Math.PI}
                                    strokeDashoffset={`${28 * 2 * Math.PI -
                                        (filter?.progressPercentage / 100) * (28 * 2 * Math.PI)
                                        }`}
                                    stroke="currentColor"
                                    fill="transparent"
                                    r="28"
                                    cx="40.5"
                                    cy="40.5"
                                />
                            </svg>
                            <div
                                className="absolute flex justify-center items-center top-0 w-full h-full m-auto cursor-pointer"
                            >
                                <p className='font-semibold tile-inlay-primary text-[15px] leading-5'>{filter?.progressPercentage}%</p>
                            </div>
                        </>
                    }
            </div>
            }
        </>
    )
}