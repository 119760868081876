import React, { useEffect, useState, useRef } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import Logo from "@components/Logo";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import LastStep from "@pages/fans/LastStep";
import emitter from "@services/emitter-service";
import moment from "moment";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import Api from "@services/api";
import Loader from "@components/Loader";
import common from "@common/common";
import ProgramBox from "@components/ProgramBox";
import { isMobileName, klaviyoEvent, validateFullName } from "@helpers/common";
import Mixpanel from "@services/mixpanel-events";

interface Props {
	steps: {
		[key: string]: StepItem[];
	};
}

interface StepItem {
	label: string;
	input: string;
	placeholder: string;
	rules: {
		[key: string]: any;
	};
	type: string;
}

const SignupSteps = ({
	form,
	next,
	prev,
	lastStep,
	creator,
	formp,
	doSignup,
}: any) => {
	const { actions } = require("@redux/UserRedux");
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [agree, setAgree] = useState(false);
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState("");
	const [message, setMessage] = useState({});

	const program = location.pathname.split("/");
	const cname = program?.[1];
	const pname = program?.[2];

	const date = new Date();
	const dateOptions: any = {
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	};

	const minAge = form.filter((i) => i.type == "date")?.[0]?.rules?.minAge || 16;
	const maxDate = new Date(
		date.getFullYear() - minAge,
		date.getMonth(),
		date.getDate()
	);
	const minDate = new Date(
		date.getFullYear() - 100,
		date.getMonth(),
		date.getDate()
	);
	const maxDateStr = new Intl.DateTimeFormat("fr-CA", dateOptions).format(
		maxDate
	);
	const minDateStr = new Intl.DateTimeFormat("fr-CA", dateOptions).format(
		minDate
	);

	const fdata = useSelector((state: any) => Object.assign({}, state.user.form));

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<{ [key: string]: string }>({});

	const addForm = (key: string, value: any) => {
		let form = fdata;
		form = {
			...form,
			extrainfo: {
				...form.extrainfo,
				[key]: value,
			},
		};
		dispatch(actions.addForm(form));
		setValue(key, value);
	};

	useEffect(() => {
		if(!fdata?.token){
			navigate(`/${cname}/${pname}`);
		} else {
			if (!fdata?.[prev]) {
				navigate(prev);
			}
		}
		
	}, [next]);

	useEffect(() => {
		let formData = { ...fdata };
		let keyPair = {};
	
		form.forEach((item) => {
			if (item?.fieldName === "ipAddress") {
				keyPair[item.fieldName] = Api.geClientIP();
				setValue(item.fieldName, Api.geClientIP());
			}
			if (item?.type === "boolean") {
				keyPair[item.fieldName] = false;
				setValue(item.fieldName, "false");
			}
		});
	
		formData = {
			...formData,
			extrainfo: {
				...formData.extrainfo,
				...keyPair,
			},
		};
	
		dispatch(actions.addForm(formData));
	
	}, []);
	

	useEffect(() => {
		if(fdata?.token) {
			Api.setClientToken(fdata?.token)
		}
	}, [])

	const handleAgree = () => {
		if (!agree) {
			Mixpanel.send("Terms Agreed")
		}
		setAgree(!agree);
	};

	const signupHandle = async () => {
		if ((fdata?.phoneNumber || "").length > 0) {
			klaviyoEvent('phone_number', fdata?.phoneNumber);
		} else {
			klaviyoEvent('email', fdata?.email);
		}
		setTimeout(() => Mixpanel.send("Account Created"), 1000);
		setLoader(true);
		let userData = {
			creatorId: creator?.creator?.id,
			programId: creator?.program?.id,
		};

		userData["extraInfo"] = formp?.extrainfo || {};

		const json = await doSignup(userData, fdata?.token, creator?.creator?.username, creator?.program?.name);

		if (json.status == 201) {
			setLoader(false);
			navigate(`/${cname}/${pname}/earn`);
			const jsonTouchpoint = await Api.fanTouchpoints(
				json?.data?.data?.programId
			);
			if (jsonTouchpoint.status == 200) {
				const touchpoints = jsonTouchpoint.data?.data?.touchpoints || [];
				const touchpoint =
					touchpoints.filter((i) => i.type == "featured" && i.active)?.[0] ||
					{};
				const action =
					(touchpoint.actions || []).filter(
						(i) => i.action == "join" && i.config?.active
					)?.[0] || {};
				if (action?.id) {
					const data = {
						score: {
							points: action?.points,
							lifetimePoints: action?.points,
						},
						data: action,
					};
					emitter.emit("NotificationPopup", [data]);
				}
			}
		}
	};

	const submit = (data: any) => {
		setError("");
		setMessage({});

		if (data?.fullName) {

			if (validateFullName(data?.fullName) == false) {
				setMessage({ fullName: "Please enter your full name" });
				return;
			}
		}

		if (data?.dateOfBirth) {
			const dateOfBirthMoment = moment(data.dateOfBirth, "MM/DD/YYYY", true);

			if (!dateOfBirthMoment.isValid()) {
				setError(
					"Please enter a valid date of birth in the format MM/DD/YYYY."
				);
				return;
			}

			if (moment().diff(dateOfBirthMoment, "years") < minAge) {
				setError(
					`You are under ${minAge} years of age. We are unable to register you.`
				);
				return;
			}

			if (
				moment().diff(dateOfBirthMoment, "years") > 100 ||
				!moment(data.dateOfBirth, "MM/DD/YYYY", true).isValid()
			) {
				setError("Please enter a correct date of birth.");
				return;
			}
		}
		if (agree) {
			signupHandle();
		}
	};

	return (
		<div>
			<form
				className={`absolute lg:py-10 md:py-10 py-5 transition-all duration-1000 ease-in-out lg:h-screen ${isMobileName() === 'ios' ? 'h-[calc(100vh-100px)]' : 'h-[calc(100vh-55px)]' } ${form?.length > 2 ? 'lg:overflow-auto md:overflow-auto' : 'lg:overflow-hidden md:overflow-hidden'} overflow-auto text-center flex flex-col w-full`}
			>
				<div className={`flex flex-col grow`}>
					<Logo className="lg:h-[135px] lg:w-[135px] h-auto rounded-full w-[100px] object-contain mx-auto" />
					<div className="flex grow flex-col lg:justify-center justify-start">
						{form.map((item: any, key: number) => (
							<div
								key={key}
								className={`flex justify-center items-center my-5 w-full ${item?.fieldName=="ipAddress" && "hidden"}`}
							>
								<div
									key={key}
									className="lg:max-w-[500px] w-full max-w-[350px] items-center px-3 flex flex-col"
								>
								
									<div className="flex flex-col gap-3 items-center">
										<h2 className="body-inlay-primary font-[550] text-[20px] lg:text-[24px] leading-6 mx-auto text-center">
											{item.label}
										</h2>
										{item.fieldName == "fullName" &&
											<p className="font-normal text-[16px] lg:text-[18px] leading-5 body-inlay-primary opacity-80">Please enter your first and last name.</p>
										}
										{item.fieldName == "dateOfBirth" &&
											<p className="font-normal text-[16px] lg:text-[18px] leading-5 body-inlay-primary opacity-80">You must be 18 or older to continue.</p>
										}
									</div>
									<div className="mt-7 w-full">
										<div className="rounded-[10px] w-full items-center relative">
											{item?.type == "date" ? (
												<InputMask
													mask="99/99/9999"
													type={"tel"}
													//value={fdata?.extrainfo?.[item.fieldName] || ""}
													{...register(item.fieldName, { ...item.rules })}
													maskPlaceholder={"mm/dd/yyyy"}
													placeholder={item?.placeholder}
													onChange={(e) => {
														addForm(item.fieldName, e.target.value)
													}}
													onBlur={(e) => Mixpanel.send("Birthday Entered", null, {birthday: e.target.value})}
													className="w-full z-30 mx-auto bg-tile-form h-[60px] px-3 text-center rounded-[10px] lg:text-[18px] text-[16px] placeholder:opacity-30 placeholder:font-normal font-normal tile-inlay-primary shadow-none outline-none"
													data-testid={`inputfield-${key}`}

												/>
											) : item?.type == "boolean" ? (

												<>
												<select
													value={fdata?.extrainfo?.[item.fieldName] || "false"}
													{...register(item.fieldName, { ...item.rules })}
													onChange={(e) =>
														addForm(item.fieldName, e.target.value === "true")
													}
													className="w-full z-30 mx-auto bg-tile-form h-[60px] px-3 text-center rounded-[10px] lg:text-[18px] text-[16px] placeholder:opacity-30 placeholder:font-normal font-normal tile-inlay-primary shadow-none outline-none"
													data-testid={`inputfield-${key}`}


												>
													<option value={"true"}>Yes</option>
													<option value={"false"}>No</option>
												</select>
												
												
												</>
											): item?.fieldName == "ipAddress" ? (
											<input
												type={"text"}
												value={Api.geClientIP()}
												{...register(item.fieldName, { ...item.rules })}
												placeholder={item?.placeholder}
												onChange={(e) =>
													addForm(item.fieldName, e.target.value)
												}
												className="w-full z-30 mx-auto bg-tile-form h-[60px] px-3 text-center rounded-[10px] lg:text-[18px] text-[16px] placeholder:opacity-30 placeholder:font-normal font-normal tile-inlay-primary shadow-none outline-none"
												data-testid={`inputfield-${key}`}
												{...(item.type == "date" && {
													max: maxDateStr,
													min: minDateStr,
												})}


											/>): (
												<input
													type={item?.type}
													value={fdata?.extrainfo?.[item.fieldName] || ""}
													{...register(item.fieldName, { ...item.rules })}
													placeholder={item?.placeholder}
													onChange={(e) =>
														addForm(item.fieldName, e.target.value)
													}
													className="w-full z-30 mx-auto bg-tile-form h-[60px] px-3 text-center rounded-[10px] lg:text-[18px] text-[16px] placeholder:opacity-30 placeholder:font-normal font-normal tile-inlay-primary shadow-none outline-none"
													data-testid={`inputfield-${key}`}
													{...(item.type == "date" && {
														max: maxDateStr,
														min: minDateStr,
													})}


												/>
											)}
										</div>
										{errors?.[item.fieldName] && (
											<p
												data-testid={`errormsg-${key}`}
												className="text-red-500 mt-2 text-xs"
											>
												{errors[item.fieldName].message}
											</p>
										)}
										{!!message?.[item.fieldName] &&

											<p
												data-testid={`error-msg-${key}`}
												className="text-red-500 mt-2 text-xs"
											>
												{message?.[item.fieldName]}
											</p>
										}
										{!!error && item.type == "date" && (
											<p
												data-testid="error"
												className="text-red-500 mt-2 text-xs"
											>
												{error}
											</p>
										)}
									</div>
								</div>
							</div>
						))}
						<div className="flex items-center justify-center gap-3">
							<label
								htmlFor="agree"
								className="cursor-pointer flex items-center text-[15px] font-normal"
							>
								<input
									id="agree"
									type="checkbox"
									className="hidden peer"
									checked={agree}
									onChange={handleAgree}
								/>
								<span className="peer-checked:bg-[#fff] bg-transparent h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.2px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] peer-checked:before:border-[#4c4c4c] before:border-transparent inline-block border-2 rounded-[4px]"></span>
							</label>
							<h6 className="body-inlay-primary font-normal  leading-[19px]">
								<span className="opacity-80 font-normal text-[16px] lg:text-[18px]">
									I agree to the{" "}
								</span>
								<a
									target="_blank"
									href={common.term_of_service_url}
									className="font-semibold text-[16px] lg:text-[18px]" rel="noreferrer"
								>
									Terms of Service.
								</a>
							</h6>
						</div>
					</div>
					<div className={`${agree && lastStep ? 'visible' : 'invisible'} lg:grow-0 mt-8 grow flex items-end flex-row justify-center`}>
						<button
							data-testid="submitbtn"
							disabled={loader}
							onClick={handleSubmit(submit)}
							className="btn-inlay-primary bg-btn hover:opacity-80 rounded-full mx-2 h-[40px] w-[120px] font-semibold text-[16px] lg:text-[18px]"
						>
							{loader ? (
								<Loader height={30} width={30}/>
							) : (
								"complete"
							)}
						</button>
					</div>
				</div>
				<button style={{ display: "none" }}
					onClick={handleSubmit(submit)}
				>
				</button>
				<ProgramBox box="POWERED_BY"></ProgramBox>
			</form>
		</div>
	);
};

const mapStateToProps = ({ creator, user }, ownProps) => {
	return {
		creator: creator,
		token: user.token,
		formp: user.form,
		next: ownProps.next,
		form: ownProps.form,
		prev: ownProps.prev,
		lastStep: ownProps.lastStep,
	};
};

const mergeProps = (stateProps: any, dispatchProps: any) => {
	const { dispatch } = dispatchProps;
	const { actions } = require("@redux/UserRedux");
	return {
		...stateProps,
		doSignup: (userData, token, cname, pname) => {
			return actions.doSignup(dispatch, userData, token, cname, pname);
		},
	};
};

export default connect(mapStateToProps, undefined, mergeProps)(SignupSteps);